import './assets/styles/main.css';

import React from 'react';

import Header from './components/Header/Header';
import Home from './components/Home'


const App = () => {
    return (
        <div className="w-100 flex flex-col">
            <Header />
            <Home />
        </div>


    )
}

export default App;
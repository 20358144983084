import React from 'react';

const NavHeader = () => {

    const toggleMenu = () => {

    }

    return (
        <div className=" flex flex-col justify-between md:justify-start items-center py-2  bg-black text-white" >
            <div className="w-full flex justify-between px-5">
                <div className="md:hidden">
                    <h3>MENU</h3>
                </div>

                <div className="md:hidden" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>
            <div className="collapse w-full px-5" id="collapseExample">
                <div className="w-full flex flex-col justify-between">
                    <div className="accordion rounded-none bg-black" id="accordionExample">
                        <div className="accordion-item rounded-none flex flex-col" style={{ borderRadius: '0px' }}>
                            <div className="flex justify-between items-center">
                                <div>MORTGAGE CALCS</div>
                                <div data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                    </svg>
                                </div>
                            </div>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body bg-black">
                                    <ul className="px-2">
                                        <li>+ 30 YR FIXED</li>
                                        <li>+ 20 YR FIXED</li>
                                        <li>+ 15 YR FIXED</li>
                                        <li>+ 10 YR FIXED</li>
                                        <li>+ 15 VS 30 YR</li>
                                        <li>+ SECOND MORTGAGE</li>
                                        <li>+ HELOC</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item rounded-none flex flex-col" style={{ borderRadius: '0px' }}>
                            <div className="flex justify-between items-center">
                                <div>REFINANCE</div>
                                <div data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                    </svg>
                                </div>
                            </div>
                            <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body bg-black">
                                    <ul className="px-2">
                                        <li>+ REFI CALCULATOR</li>
                                        <li>+ REFI ADVICE</li>
                                        <li>+ REFI COSTS</li>
                                        <li>+ WHEN TO REFI</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden px-40 w-full md:flex md:justify-center md:px-10 lg:px-40  ">
                <ul className="flex justify-evenly items-center w-full " style={{ color: 'white' }}>
                    <li style={{ position: 'relative' }} className="hoverMenu">Mortgage Calcs
                        <ul className="hoverMenuList">
                            <li><a href="#">30 yr Fixed</a></li>
                            <li><a href="#">20 yr Fixed</a></li>
                            <li><a href="#">15 yr Fixed</a></li>
                            <li><a href="#">10 yr Fixed</a></li>
                            <li><a href="#">15 vs 30 yr</a></li>
                            <li><a href="#">Second Mortgage</a></li>
                            <li><a href="#">HELOC</a></li>
                        </ul>
                    </li>
                    <li style={{ position: 'relative' }} className="hoverMenu">+ Refinance
                        <ul className="hoverMenuList">
                        <li><a href="#">Refi Calculator</a></li>
                            <li><a href="#">Refi Advice</a></li>
                            <li><a href="#">Refi Lists</a></li>
                            <li><a href="#">When to Refi</a></li>
                        </ul>
                    </li>
                    <li style={{ position: 'relative' }} className="hoverMenu">+ Rates
                        <ul className="hoverMenuList">
                        <li><a href="#">FHA Loans</a></li>
                            <li><a href="#">VA Loans</a></li>
                            <li><a href="#">USDA Loans</a></li>
                            <li><a href="#">Real APR</a></li>
                        </ul>
                    </li>
                    <a href="#"><li>+ Refinance</li></a>
                    <a href="#"><li>+ Rates</li></a>
                    <a href="#"><li>+ ARM</li></a>
                    <a href="#"><li>+ Affordability</li></a>
                    <a href="#"><li>+ Save Money</li></a>
                    <a href="#"><li>+ Advice</li></a>
                    <a href="#"><li>+ Financial Calcs</li></a>
                    <a href="#"><li>+ Widgets</li></a>
                </ul>
            </div>
        </div >
    );
};

export default NavHeader;
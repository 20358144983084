import React from 'react';
import token from '../../assets/images/token.png';
import TopHeader from './TopHeader';
import NavHeader from './NavHeader';

const Header = () => {
    return (
        <div className="w-full h-30  flex flex-col">
            <TopHeader />
            <NavHeader />
        </div>
    );
};

export default Header;
import React, {useState} from 'react';

const MortgageRepaymentSummary = () => {

    const [homeValue, setHomeValue] = useState(400000);
    const [downPayment, setDownPayment] = useState(5);
    const [loanAmount, setLoanAmount] = useState(320000);
    const [interestRate, setInterestRate] = useState(5.1);
    const [loanTerm, setLoanTerm] = useState(30);
    const [startYear, setStartYear] = useState(2022);
    const [propertyTax, setPropertyTax] = useState(2400);
    const [monthlyHoa, setMonthlyHoa] = useState(0);

    const [downPaymentAmount, setDownPaymentAmount] = useState(80000);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(5);
    const [dollarPercentage, setDollarPercentage] = useState('percentage');


    const onInputChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (re.test(e.target.value) || e.target.value === '') {
            if (e.target.id === 'homeValue') {
                if (e.target.value !== '') {
                    if (homeValue === '0') {
                        let str = e.target.value;
                        str = str.split('');
                        [, str] = str
                        setHomeValue(str);
                    } else {
                        setHomeValue(e.target.value);
                    }
                } else {
                    setHomeValue('0');
                }
            }
            if (e.target.id === 'downPayment') {
                if (e.target.value !== '') {
                    if (downPayment === '0') {
                        let str = e.target.value;
                        str = str.split('');
                        [, str] = str
                        setDownPayment(str);
                    } else {
                        setDownPayment(e.target.value)
                    }
                } else {
                    setDownPayment('0')
                }
            }
            if (e.target.id === 'loanAmount') {
                setLoanAmount(e.target.value);
            }
            if (e.target.id === 'interestRate') {
                setInterestRate(e.target.value);
            }
            if (e.target.id === 'loanTerm') {
                setLoanTerm(e.target.value);
            }
            if (e.target.id === 'startYear') {
                setStartYear(e.target.value);
            }
            if (e.target.id === 'propertyTax') {
                setPropertyTax(e.target.value);
            }
            if (e.target.id === 'monthlyHoa') {
                setMonthlyHoa(e.target.value);
            }
        }
        if (e.target.name === 'inlineRadioOptions') {
            setDollarPercentage(e.target.value)
        }
    }


    const buttonClick = () => {
        console.log(dollarPercentage)
        if (dollarPercentage === 'percentage') {
            setDownPaymentPercentage(downPayment)
            setDownPaymentAmount((downPayment / 100) * homeValue)
        }
        if (dollarPercentage === 'dollar') {
            setDownPaymentAmount(downPayment)
            setDownPaymentPercentage((downPayment / homeValue) * 100)
        }
    }


    return (
        <div className="flex flex-col md:flex-row md:justify-center md:py-10 ">
            <div className="px-4 py-4  w-full md:w-3/6 ">
                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Home Value:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="homeValue"
                        onChange={onInputChange}
                        // defaultValue={homeValue}
                        value={homeValue}
                    />
                    <span className="w-1/5 ml-2">$</span>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/6">Down payment:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/6
                        "
                        id="downPayment"
                        onChange={onInputChange}
                        value={downPayment}
                    />
                    <div className="flex justify-center items-center  ml-1 w-2/6">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer"
                                type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="dollar"
                                onChange={onInputChange} checked={dollarPercentage === 'dollar'}/>
                            <label className="form-check-label inline-block text-gray-800"
                                   htmlFor="inlineRadio10">$</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer"
                                type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="percentage"
                                onChange={onInputChange} checked={dollarPercentage === 'percentage'}/>
                            <label className="form-check-label inline-block text-gray-800"
                                   htmlFor="inlineRadio20">%</label>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Loan Amount:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="loanAmount"
                        onChange={onInputChange}
                        defaultValue={loanAmount}
                    />
                    <span className="w-1/5 ml-2">$</span>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Interest Rate:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="interestRate"
                        onChange={onInputChange}
                        defaultValue={interestRate}
                    />
                    <span className="w-1/5 ml-2">%</span>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                >
                    <button type="button"
                            className="ml-4 px-12 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                            style={{backgroundColor: '#1d97cf'}}>See Los Angeles's Best Rates
                    </button>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Loan Term:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="loanTerm"
                        onChange={onInputChange}
                        defaultValue={loanTerm}
                    />
                    <span className="w-1/5 ml-2">years</span>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/6">Start Date:</label>
                    <select className="form-select form-select-sm
                        appearance-none
                        block
                        w-2/6
                        px-2
                        py-1
                        text-sm
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label=".form-select-sm example">
                        <option defaultValue="1">Jan</option>
                        <option defaultValue="2">Feb</option>
                        <option defaultValue="3">Mar</option>
                        <option defaultValue="4">Apr</option>
                        <option defaultValue="5">May</option>
                        <option defaultValue="6">Jun</option>
                        <option defaultValue="7">Jul</option>
                        <option defaultValue="8">Aug</option>
                        <option defaultValue="9">Sep</option>
                        <option defaultValue="10">Oct</option>
                        <option defaultValue="11">Nov</option>
                        <option defaultValue="12">Dec</option>
                    </select>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            ml-2
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/6
                        "
                        id="startYear"
                        defaultValue={startYear}
                    />
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Property Tax:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="propertyTax"
                        defaultValue={propertyTax}
                    />
                    <span className="w-1/5 ml-2">$/yr</span>
                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Monthly HOA:</label>
                    <input
                        type="text"
                        className="
                            form-control
                            block
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            w-2/5
                        "
                        id="monthlyHoa"
                        defaultValue={monthlyHoa}
                    />
                    <span className="w-1/5 ml-2">$</span>
                </div>

                <div className="flex justify-start items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/6">Loan Type:</label>
                    <select className="form-select form-select-sm
                        appearance-none
                        block
                        w-3/6
                        px-2
                        py-1
                        text-sm
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label=".form-select-sm example">
                        <option defaultValue="4">Conventional</option>
                        <option defaultValue="1">FHA</option>
                        <option defaultValue="2">VA</option>
                        <option defaultValue="3">USDA</option>
                    </select>

                </div>

                <div className="flex justify-center items-center px-2 py-2 bg-slate-100 border-b-1 "
                     style={{borderBottom: '1px solid white'}}>
                    <label className="w-2/5">Buy or Refi:</label>
                    <select className="form-select form-select-sm
                        appearance-none
                        block
                        w-2/6
                        px-2
                        py-1
                        text-sm
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label=".form-select-sm example">
                        <option defaultValue="Refi">Refi</option>
                        <option defaultValue="Buy">Buy</option>
                        <option defaultValue="Select">Select</option>
                    </select>
                    <span className="w-1/5 ml-2">$</span>
                </div>

                <div className="flex justify-center items-center px-2 py-4  border-b-1 bg-black"
                >
                    <button type="button"
                            className="ml-4 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                            style={{backgroundColor: '#1d97cf'}}
                            onClick={buttonClick}>Calculate
                    </button>

                </div>
            </div>
            <div className="px-4 py-4  w-full md:w-3/6 ">
                <div className="w-full py-4 ">
                    <h3>Mortgage Repayment Summary</h3>
                </div>
                <div className="flex flex-wrap w-full">
                    <div className="w-3/6 px-2 py-4">
                        <h4>$1,401.63</h4>
                        <p>Total Monthly Payment</p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>PMI</h4>
                        <p>not required</p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>${downPaymentAmount}</h4>
                        <p>Down payment amount</p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>{downPaymentPercentage}%</h4>
                        <p>Down payment %</p>
                    </div>
                </div>
                <div className="w-full py-4 ">
                    <p className="underline underline-offset-1" style={{color: 'blue', fontWeight: '600'}}>
                        <a href="#">Veterans & Military: Check Your Eligibility for 0% Down</a></p>
                </div>
                <div className="flex flex-wrap w-full">
                    <div className="w-3/6 px-2 py-4">
                        <h4>Feb, 2052</h4>
                        <p>Loan pay-off date</p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$162,587.15</h4>
                        <p>Total Interest Paid </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$200.00</h4>
                        <p>Monthly Tax Paid </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$72,000.00</h4>
                        <p> Total Tax Paid </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$83.33</h4>
                        <p> Monthly Home Insurance </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$30,000.00</h4>
                        <p> Total Home Insurance </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$16,819.57</h4>
                        <p> Annual Payment Amount </p>
                    </div>
                    <div className="w-3/6 px-2 py-4">
                        <h4>$504,587.15</h4>
                        <p> Total of 360 Payments </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MortgageRepaymentSummary;
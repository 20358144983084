import React from 'react';
import token from '../../assets/images/token.png';

const TopHeader = () => {
    return (
            <div className="flex justify-center items-center w-full" style={{padding:'10px'}} >
                <a href="#" className="w-3/5 lg:w-2/4">
                    <img src={token} className="w-full" />
                </a>

                


            </div>
    );
};

export default TopHeader;
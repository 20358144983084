import React from 'react';

const MortgagePaymentInformation = () => {
    return (
        <div className="flex flex-col justify-between items-center">
            <div className="px-5 py-5 text-black">
                <h1 className="text-xl md:text-2xl">Mortgage Calculator</h1>
            </div>
            <div className="px-5 py-4 text-white flex justify-center items-center w-full" style={{backgroundColor:'#155776'}}>
                <p>Your Mortgage Payment Information</p>
            </div>
        </div>
    );
};

export default MortgagePaymentInformation;
import React from 'react';
import MortgagePaymentInformation from './MortgagePaymentInformation';
import MortgageRepaymentSummary from './MortgageRepaymentSummary';

const Home = () => {
    return (
        <div className="flex flex-col px-2 md:px-40">
            <MortgagePaymentInformation />
            <MortgageRepaymentSummary />
        </div>
    );
};

export default Home;